import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./HeroImage.scss";
import Popup from "reactjs-popup";
import ContactForm from "client/components/organisms/forms/contact-form/ContactForm";

// Képek importálása
import heroImg1 from "resources/images/hero1.webp";
import heroImg2 from "resources/images/hero2.webp";
import heroImg3 from "resources/images/slide-3.png.webp";

function scrollToElement(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export default function HeroImage() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const isHungarian = i18n.language === "hu";

  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      autoplay={{ delay: 8000 }}
      loop={true}
      className="heroSlider"
    >
      <SwiperSlide>
        <div className="slide" style={{ backgroundImage: `url(${heroImg1})` }}>
          <div className="container">
            <div className="text-container">
              <h2>{t("hero.title")}</h2>
              <p>{t("hero.text")}</p>
              <div className="cta-buttons">
                <Popup
                  trigger={
                    <button className="hero-button">
                      {t("book_appointment")}
                    </button>
                  }
                  position="center center"
                  modal={true}
                  closeOnDocumentClick
                  lockScroll={true}
                >
                  {(close) => (
                    <div className="contact-popup">
                      <h3>{t("appointmentPopup.title")}</h3>
                      <p>
                        <p className="declaration">
                          {t("appointmentPopup.text")}
                        </p>
                      </p>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                      <ContactForm />
                    </div>
                  )}
                </Popup>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      {/* Slide 2 – Csak magyar nyelvnél */}
      {isHungarian && (
        <SwiperSlide>
          <div
            className="slide"
            style={{ backgroundImage: `url(${heroImg2})` }}
          >
            <div className="container">
              <div className="text-container">
                <h2>Fájdalommentes kezelések</h2>
                <p>Modern technológiával dolgozunk</p>
                <div className="cta-buttons">
                  <button
                    onClick={() => scrollToElement("home-treatments")}
                    className="hero-button"
                  >
                    🦷 Tudjon meg többet!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      )}

      {/* Slide 3 – Csak magyar nyelvnél */}
      {isHungarian && (
        <SwiperSlide>
          <div
            className="slide"
            style={{ backgroundImage: `url(${heroImg3})` }}
          >
            <div className="container">
              <div className="text-container">
                <h2>Professzionális fogászati ellátás</h2>
                <p>Tapasztalt szakorvosaink várják Önt</p>
                <div className="cta-buttons">
                  <Link to="/blog" className="hero-button">
                    📖 Olvassa el a blogunkat
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  );
}
