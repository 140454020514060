import { Container } from "react-bootstrap";
import zsombor from "resources/images/horvath_zsombor-300x300.webp";
import zsolt from "resources/images/horvath_zsolt-300x300.webp";
import { useTranslation } from "react-i18next";
import "./Doctors.scss";

export default function Doctors() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const isHungarian = i18n.language === "hu";
  return (
    <section className="content doctors">
      <Container>
        <h2>{t("doctors.headline")}</h2>
        <p>{t("doctors.text")}</p>
        <div className="row">
          <div className="col-sm-12">
            <div className="doctor">
              <div className="doctor-image">
                <div
                  className="translationTxt js-translationTxt"
                  data-translationkey="doctor1.image"
                >
                  <img
                    src={zsombor}
                    alt="Dr. Horváth Zsombor"
                    width="300"
                    height="300"
                  />
                </div>{" "}
              </div>
              <h3>
                <div
                  className="translationTxt js-translationTxt"
                  data-translationkey="doctor1.headline"
                >
                  Dr. Horváth Zsombor
                </div>
              </h3>
              <div className="doctor-text">
                {isHungarian && (
                  <div
                    className="translationTxt js-translationTxt"
                    data-translationkey="doctor1.text"
                  >
                    Fog- és szájbetegségek orvosa
                    <div>
                      Hívható nyitvatartási időben a<br />
                      <a href="tel:+36309798436" className="">
                        +36 30 979-8436
                      </a>
                      számon
                    </div>
                    <div>
                      <br />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
